import { ContentfulScreenReaderText } from '@truckmap/common';

type Props = {
  entries: ContentfulScreenReaderText[];
  slug: string;
};

const ScreenReaderOnly: React.FC<Props> = ({ entries, slug }) => {
  const entry = entries.find((f) => f.slug === slug);
  return <span className="sr-only">{entry ? entry.content : ''}</span>;
};

export { ScreenReaderOnly };
