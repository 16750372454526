import { Button } from 'components/common/Button';
import { Translation } from 'components/common/Translation';
import { SmallText } from 'components/common/Typography';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { makeStyles } from 'lib/makeStyles';
import { PublicURLModel } from 'models/PublicURLModel';
import React from 'react';
import { useFormState } from 'react-hook-form';
import { featureFlag } from 'truckMapConfig/featureFlags';

const useStyles = makeStyles({
  buttonSection: ['mt-3.5 w-full', 'flex flex-col-reverse gap-2']
});

const AuthEmailSubmitModal = React.memo(() => {
  const formState = useFormState();
  const styles = useStyles();

  return (
    <div className={styles.buttonSection}>
      <PanelItem className="whitespace-nowrap" justify="center">
        <SmallText color="neutral">
          <Translation textKey="SIGNUP_NAME_EMAIL_FOOTER" />
        </SmallText>
        <Button borderless isSmall as="a" href={PublicURLModel.LOGOUT}>
          <SmallText>
            <Translation textKey="BUTTON_LOGOUT" />
          </SmallText>
        </Button>
      </PanelItem>
      <Button
        isPrimary
        fullWidth
        type="submit"
        as="button"
        disabled={!formState.isDirty || !formState.isValid}>
        {featureFlag.dashboard ? (
          <Translation textKey="BUTTON_GO_TO_DASHBOARD" />
        ) : (
          <Translation textKey="CONTINUE" />
        )}
      </Button>
    </div>
  );
});

AuthEmailSubmitModal.displayName = 'AuthEmailSubmitModal';

export { AuthEmailSubmitModal };
