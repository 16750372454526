import { Logo } from 'components/common/Logo';
import { Translation } from 'components/common/Translation';
import { HeadingText, MetaText } from 'components/common/Typography';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { pingEvent } from 'lib/analytics/event';
import { makeStyles } from 'lib/makeStyles';
import { PrivateURLModel } from 'models/PrivateURLModel';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { featureFlag } from 'truckMapConfig/featureFlags';

import { useAuthFormContext } from '../AuthFormContext';
import { AuthEmailForm } from './AuthEmailForm';
import { AuthEmailSubmitModal } from './AuthEmailSubmitModal';

type AuthUsernameProps = ParentClassNameProp;

const useStyles = makeStyles<AuthUsernameProps>()({
  root: (props) => [props.className],
  heading: ['w-full', 'text-center', 'text-brightBlue-600'],
  buttonSection: 'mt-3.5 flex flex-col-reverse gap-2',
  header: 'mb-1',
  logo: 'w-[30px] mb-2'
});

export const AuthEmail = React.memo((props: AuthUsernameProps) => {
  const styles = useStyles(props);
  const { setFormStep } = useAuthFormContext();
  const router = useRouter();

  const onFormSubmit = useCallback(() => {
    setFormStep(false);
    pingEvent('AUTH_USERNAME_SUBMIT');
    if (featureFlag.dashboard) {
      router.push(PrivateURLModel.DASHBOARD_HOME);
    }
  }, [setFormStep, router]);

  return (
    <div className={styles.root}>
      <PanelItem className={styles.header} align="center" column>
        <Logo className={styles.logo} disableLink />
        <HeadingText bold size="XS" as="h3" className={styles.heading}>
          <Translation textKey="SIGNUP_NAME_EMAIL_HEADING" />
        </HeadingText>
        <MetaText thin>
          <Translation textKey="SIGNUP_NAME_EMAIL_SUBHEADING" />
        </MetaText>
      </PanelItem>
      <AuthEmailForm onSubmit={onFormSubmit}>
        <AuthEmailSubmitModal />
      </AuthEmailForm>
    </div>
  );
});

AuthEmail.displayName = 'AuthEmail';
