import { GenericSection } from 'components/contentful/Section/GenericSection';
import React from 'react';
import { IUiComponent } from 'types/generated/contentful';

export type PageUIComponentsProps = ChildrenProp & {
  uiComponents: IUiComponent[];
};

export const PageUIComponents: React.ComponentType<PageUIComponentsProps> = React.memo(
  ({ children, uiComponents }) => (
    <main>
      {uiComponents.map((entry) => (
        <section key={entry.sys.id} id={`section-${entry.sys.id}`}>
          <GenericSection entry={entry} />
        </section>
      ))}
      {children}
    </main>
  )
);

PageUIComponents.displayName = 'PageUIComponents';

// avoid server TypeError: Cannot read properties of undefined (reading 'map')
PageUIComponents.defaultProps = {
  uiComponents: []
};
