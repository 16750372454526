import { Breakpoint } from 'components/common/Breakpoint';
import { SettingsModal } from 'components/dashboard/Settings/SettingsModal';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { useToggle } from 'hooks/useToggle';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { DashboardButton } from './DashboardButton';
import { UserMenuDesktop } from './UserMenuDesktop';
import { UserMenuMobile } from './UserMenuMobile';

type UserMenuProps = ParentClassNameProp;

const useStyles = makeStyles<UserMenuProps>()({
  root: (props) => [props.className]
});

export const UserMenu = React.memo((props: UserMenuProps) => {
  const styles = useStyles(props);
  const { enable: showSettings, status: openSettings, disable: hideSettings } = useToggle();

  return (
    <div className={styles.root}>
      <PanelItem>
        <Breakpoint up="LG">
          <DashboardButton />
        </Breakpoint>
        <UserMenuDesktop showSettings={showSettings} />
        <UserMenuMobile showSettings={showSettings} />
      </PanelItem>
      <SettingsModal open={openSettings} onClose={hideSettings} onSubmit={hideSettings} />
    </div>
  );
});

UserMenu.displayName = 'UserMenu';
