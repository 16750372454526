import { isEmail, updateUser } from '@truckmap/common';
import { globalAlert } from 'components/common/Alert/AlertGlobalHotToast';
import { TextInput } from 'components/common/Input';
import { Translation } from 'components/common/Translation';
import { MetaText } from 'components/common/Typography';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { produce } from 'immer';
import React, { useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { userAtom } from 'recoil/user';

type AuthUsernameFormProps = {
  onSubmit?: GenericHandler;
  fillValues?: boolean;
} & ChildrenProp;

const nameInputName = 'auth_name';
const emailInputName = 'auth_email';

const AuthEmailForm: React.ComponentType<AuthUsernameFormProps> = React.memo((props) => {
  const { t } = useTranslation();
  const formMethods = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const { control, handleSubmit } = formMethods;
  const [userState, setUserState] = useRecoilState(userAtom);
  const onSubmitUsername = useCallback(
    async (params) => {
      try {
        const user = await updateUser({
          name: params[nameInputName],
          email: params[emailInputName]
        });

        setUserState(
          produce(userState, (draft) => {
            draft.user = user;
          })
        );

        props?.onSubmit();
      } catch (e) {
        globalAlert({
          intent: 'danger',
          message: t('SIGNUP_CODE_ERROR'),
          isSmall: true
        });
      }
    },
    [setUserState]
  );

  const defaultValues = {
    [nameInputName]: props.fillValues ? userState?.user?.name : '',
    [emailInputName]: props.fillValues ? userState?.user?.email : ''
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmitUsername)}>
        <PanelItem column align="start">
          <MetaText as="label" htmlFor={nameInputName}>
            <Translation textKey="SIGNUP_NAME_LABEL" />
          </MetaText>
          <Controller
            render={({ field }) => (
              <TextInput placeholder="Your name" fullWidth id={nameInputName} {...field} />
            )}
            name={nameInputName}
            control={control}
            rules={{ required: true, minLength: 2 }}
            defaultValue={defaultValues[nameInputName]}
          />
        </PanelItem>
        <PanelItem column align="start">
          <MetaText as="label" htmlFor={emailInputName}>
            <Translation textKey="SIGNUP_EMAIL_LABEL" />
          </MetaText>
          <Controller
            render={({ field }) => (
              <TextInput
                type="email"
                placeholder="example@email.com"
                fullWidth
                id={emailInputName}
                {...field}
              />
            )}
            name={emailInputName}
            control={control}
            rules={{
              required: true,
              validate: isEmail
            }}
            defaultValue={defaultValues[emailInputName]}
          />
          {props.children}
        </PanelItem>
      </form>
    </FormProvider>
  );
});

AuthEmailForm.displayName = 'AuthEmailForm';

export { AuthEmailForm };
