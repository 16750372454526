import { makeStyles } from 'lib/makeStyles';
import { PublicURLModel } from 'models/PublicURLModel';
import { memo } from 'react';

import { ButtonOwnProps } from './common/Button';
import { Translation } from './common/Translation';
import { MetaText } from './common/Typography';

type LogoutLinkProps = ParentClassNameProp & {
  hasPrimaryText?: boolean;
  hasPaddingY?: boolean;
  bold?: boolean;
};

const useStyles = makeStyles<LogoutLinkProps>()({
  root: (props) => [
    'text-left text-tmBlue font-semibold text-small',
    'items-center',
    'block',
    {
      'py-2': props.hasPaddingY
    },
    props.className
  ]
});

// LogoutLink should not use next/link to avoid side effects on mouse over
export const LogoutLink: React.ComponentType<
  React.AnchorHTMLAttributes<HTMLAnchorElement> & ButtonOwnProps & LogoutLinkProps
> = memo(({ children, hasPrimaryText, hasPaddingY = true, bold, ...props }) => {
  const styles = useStyles({ hasPaddingY, ...props });

  return (
    <a {...props} className={styles.root} href={PublicURLModel.LOGOUT}>
      {children ?? (
        <MetaText active={hasPrimaryText} bold={bold}>
          <Translation textKey="BUTTON_LOGOUT" />
        </MetaText>
      )}
    </a>
  );
});

LogoutLink.displayName = 'LogoutLink';
