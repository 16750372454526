import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { NavLinkComponent } from './NavLinkComponent';
import { NavLinkProps } from './types';

export const useStyles = makeStyles({
  icon: 'shrink-0 text-secondaryText mr-6',
  primaryText: 'text-sm font-medium text-secondaryText',
  secondaryText: 'mt-1 text-xs text-neutralTextLoud'
});

const NavLink = React.memo((props: NavLinkProps) => {
  const { primaryLink } = props;
  const url = primaryLink?.url || '/';
  const isPlacesMiddleware = url === '/places';

  return <NavLinkComponent {...props} href={url} isPrefetchLink={!isPlacesMiddleware} />;
});

NavLink.displayName = 'NavLink';

export { NavLink };

// TODO add to Storybook
