import { Button } from 'components/common/Button';
import { Translation } from 'components/common/Translation';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { useFormState } from 'react-hook-form';

const useStyles = makeStyles({
  buttonSection: 'mt-3.5 flex flex-col-reverse gap-2'
});

const GeneralSettingsSubmit = React.memo(() => {
  const formState = useFormState();
  const styles = useStyles();

  return (
    <div className={styles.buttonSection}>
      <Button isPrimary fullWidth type="submit" disabled={!formState.isDirty || !formState.isValid}>
        <Translation textKey="CONFIRM_BUTTON" />
      </Button>
    </div>
  );
});

GeneralSettingsSubmit.displayName = 'GeneralSettings';

export { GeneralSettingsSubmit };
