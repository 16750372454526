const heights = [72, 52];
const widths = [350, 200];

export function calculateNavigationStyles({ currentItems, currentRef }): {
  width: string;
  height: string;
  left: number;
} {
  if (!currentRef) return;
  const hasIcon = currentItems.find((i) => i.icon) ? 0 : 1;
  const container = { height: heights[hasIcon] * currentItems.length, width: widths[hasIcon] };

  const parent = document.getElementById('navbarContainer').getBoundingClientRect();
  const { left } = currentRef.getBoundingClientRect();
  const containerLeft = left - parent.left;

  return {
    width: `${container.width}px`,
    height: `${container.height}px`,
    left: containerLeft
  };
}
