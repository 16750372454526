import { RoundButton } from 'components/common/Button';
import { ScreenReaderOnly } from 'components/contentful/ScreenReaderOnly';
import {
  useContentfulUiComponentFromQuery,
  useContentfulUiComponentItems
} from 'hooks/useContentful/useContentful';
import { useHideDriftWidget } from 'hooks/useDriftWidget';
import { useOnRouteChange } from 'hooks/useOnRouteChange';
import { HamburgerIcon } from 'icons/monochrome/HamburgerIcon';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { mobileMenuAtom, useHideMobileMenu, useToggleMobileMenu } from 'recoil/mobileMenu';

import { HamburgerMotion } from './HamburgerMotion';
import { MobileNavigationModal } from './MobileNavigationModal';

const useStyles = makeStyles({
  circleButton: 'text-sm focus-default',
  hamburgerIcon: 'text-xs'
});

const MobileNavigation = () => {
  const styles = useStyles();
  const { open: mobileOpen } = useRecoilValue(mobileMenuAtom);
  const publicNavbarContent = useContentfulUiComponentFromQuery('public-navbar');
  const [mobileNav] = useContentfulUiComponentItems(publicNavbarContent, 'MobileNav');
  const hideMobileMenu = useHideMobileMenu();
  const handleClick = useToggleMobileMenu();

  useOnRouteChange({
    action: hideMobileMenu
  });

  useHideDriftWidget(mobileOpen);

  return (
    <>
      <HamburgerMotion>
        <RoundButton
          intent="secondary"
          circle="LG"
          onClick={handleClick}
          className={styles.circleButton}>
          <ScreenReaderOnly entries={mobileNav.srOnlyCollection.items} slug="open-menu" />
          <HamburgerIcon className={styles.hamburgerIcon} />
        </RoundButton>
      </HamburgerMotion>
      <MobileNavigationModal />
    </>
  );
};

export { MobileNavigation };
