import { Props as NavbarItemComponentProps } from 'components/PublicNavigation/NavbarItem';
import { noop } from 'lib/backfillData';
import React, { useCallback } from 'react';

import { useCustomKeyDown } from '../useKeyboard';
import { useNavigation } from './useNavigation';

type UseNavbarItemProps = Pick<NavbarItemComponentProps, 'onKeyEscape' | 'onMouseEnter' | 'index'>;

/**
 * Handles NavbarItem mouse and keyboard interactions
 * @param onMouseEnter (index: number) => void
 * @param onKeyEscape () => void
 * @param index number
 * @returns object onMouseEnterProxy, handleCustomKeyDown, onShiftTab
 */
export const useNavbarItem = ({ onKeyEscape, onMouseEnter, index }: UseNavbarItemProps) => {
  const {
    state: { currentIndex, dropDownVisible }
  } = useNavigation();

  const onMouseEnterProxy = React.useCallback(() => {
    if (onMouseEnter) {
      onMouseEnter(index);
    }
  }, [onMouseEnter]);

  const onShiftTab = useCallback(() => {
    dropDownVisible && onKeyEscape();
  }, [dropDownVisible, currentIndex]);

  const handleCustomKeyDown = useCustomKeyDown({
    onEnterOrSpace: onMouseEnterProxy,
    onEscape: onKeyEscape,
    onShiftTab: dropDownVisible ? () => onShiftTab() : noop
  });

  return {
    onMouseEnterProxy,
    handleCustomKeyDown,
    onShiftTab
  };
};
