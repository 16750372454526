import clsx from 'clsx';
import { AuthGate } from 'components/Auth';
import { DownloadBanner } from 'components/Banner/DownloadBanner';
import { Logo } from 'components/common/Logo';
import { RuntimeFeatureEnabled } from 'components/FeatureEnabled';
import { Container } from 'components/layout/Container';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { MobileNavigation } from 'components/MobileNavigation';
import { NavbarItems } from 'components/PublicNavigation/NavbarItems';
import { Props as SearchInputProps } from 'components/PublicSearch/PublicSearch';
import { TruckOptionsModal } from 'components/TruckOptions/TruckOptionsModal';
import {
  useContentfulUiComponentFromQuery,
  useContentfulUiComponentItems
} from 'hooks/useContentful/useContentful';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { useDesktopBreakpoint } from 'hooks/useUI/useDesktopBreakpoint';
import { useStickyVariant } from 'hooks/useUI/useStickyVariant';
import { makeStyles } from 'lib/makeStyles';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { autoCompleteAtom } from 'recoil/autoComplete';

type PublicNavigationProps = {
  background?: string;
};

const PublicSearchInput = dynamic<SearchInputProps>(
  () => import('components/PublicSearch/PublicSearch').then((mod) => mod.PublicSearch),
  { ssr: false }
);

const PlaceSearchInput = dynamic(
  () =>
    import('components/Place/PlaceSearch/PlaceSearchInput/PlaceSearchInput').then(
      (mod) => mod.PlaceSearchInput
    ),
  { ssr: false }
);

export const useStyles = makeStyles<PublicNavigationProps>()({
  root: ({ className }) => ['h-18 md:h-20', className],
  inner: ({ background = 'neutralBackground' }) => [
    'w-full',
    'fixed z-public-navigation',
    `bg-${background}`,
    'dark:bg-transparent',
    'tmsticky:bg-neutralBackgroundSoft tmsticky:shadow-md',
    'duration-200 lg:duration-300 transition-all'
  ],
  container: 'px-3 md:px-6',
  contentPanelItem: ['h-16 md:h-20'],
  navbarPanelItemWrapper: ['lg:block', 'hidden', 'relative'],
  buttonsContainer: ['flex', 'pl-4', 'space-x-4'],
  splitter: ['h-5 w-0.5', 'bg-primaryBorder', 'mx-3'],
  logo: 'h-10',
  mobileNav: 'lg:hidden'
});

export const PublicNavigation = (props: PublicNavigationProps) => {
  const { pathname } = useRouter();
  const { t } = useTranslation();
  const publicNavbarContent = useContentfulUiComponentFromQuery('public-navbar');

  if (!publicNavbarContent) {
    return <></>;
  }

  const [searchInput] = useContentfulUiComponentItems(publicNavbarContent, 'SearchInput');
  const navbarLinks = useContentfulUiComponentItems(publicNavbarContent, 'NavbarLink');
  const { visible: searchVisible } = useRecoilValue(autoCompleteAtom('publicSearch'));
  const thresholdRef = useStickyVariant();
  const styles = useStyles(props);
  const isDesktop = useDesktopBreakpoint();
  const includesPlacesPath = useMemo(() => pathname.includes('/places'), [pathname]);

  return (
    <header>
      <div ref={thresholdRef} />
      <nav title={t('NAVIGATION')}>
        <div className={styles.root}>
          <div className={styles.inner}>
            <Container className={clsx(searchVisible && styles.container)}>
              <PanelItem
                spacing="XL"
                justify="between"
                fullWidth
                paddingX="LG"
                className={styles.contentPanelItem}>
                <PanelItem spacing="XL" justify="start" paddingY={false}>
                  <Logo className={styles.logo} />
                  <div className={styles.mobileNav}>
                    <DownloadBanner />
                  </div>
                </PanelItem>
                {includesPlacesPath && isDesktop ? (
                  <PlaceSearchInput />
                ) : (
                  <PublicSearchInput placeholder={searchInput.primaryText} />
                )}
                <div className={styles.mobileNav}>
                  <MobileNavigation />
                </div>
                <div
                  id="navbarContainer"
                  className={styles.navbarPanelItemWrapper}
                  suppressHydrationWarning>
                  <PanelFlex spacing="2XL">
                    <NavbarItems items={navbarLinks} />
                    <RuntimeFeatureEnabled name="login">
                      <AuthGate />
                    </RuntimeFeatureEnabled>
                  </PanelFlex>
                  <TruckOptionsModal />
                </div>
              </PanelItem>
            </Container>
          </div>
        </div>
      </nav>
    </header>
  );
};
