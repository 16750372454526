import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { truckMapConfig } from 'truckMapConfig';

const useSEOLocaleRoutes = (relativePath: string) => {
  const router = useRouter();
  const { baseUrl } = truckMapConfig;

  const languageAlternates = useMemo(
    () =>
      router?.locale
        ? truckMapConfig.i18n.locales.map((locale) => ({
            hrefLang: locale,
            href: baseUrl + '/' + locale + relativePath
          }))
        : undefined,
    [relativePath]
  );

  const localeRoute = router?.locale
    ? router.locale !== 'en-US'
      ? '/' + router.locale
      : ''
    : undefined;

  const nextSEOCanonicalUrl = localeRoute
    ? `${baseUrl}${localeRoute}${router?.asPath}`
    : `${baseUrl}${router?.asPath}`;

  return {
    localeRoute,
    languageAlternates,
    baseUrl,
    nextSEOCanonicalUrl
  };
};

export { useSEOLocaleRoutes };
