import clsx from 'clsx';
import { ColorVariants } from 'models/LayoutModel';
import React from 'react';

type ColorVariantWrapperProps = {
  className?: string;
  variant?: string;
} & ChildrenProp;

const ColorVariantWrapper: React.ComponentType<ColorVariantWrapperProps> = React.memo(
  ({ className, variant = ColorVariants.DEFAULT, ...props }) => (
    <div className={clsx(className, variant)} {...props} />
  )
);

ColorVariantWrapper.displayName = 'ColorVariantWrapper';

export { ColorVariantWrapper };
