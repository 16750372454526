import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

export const AndroidIcon = React.memo((props: GlyphProps) => (
  <Glyph width={15} height={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6712 0.0640204C10.8428 0.180725 10.8891 0.417243 10.7748 0.592299L10.1073 1.61399C11.2289 2.42813 11.9583 3.74973 11.9583 5.24169V11.5979C11.9583 12.3459 11.3519 12.9523 10.6038 12.9523H10.4634V14.8801C10.4634 15.4986 9.96202 16 9.34351 16C8.725 16 8.2236 15.4986 8.2236 14.8801V12.9523H6.73362V14.8801C6.73362 15.4986 6.23222 16 5.61371 16C4.9952 16 4.49379 15.4986 4.49379 14.8801V12.9523H4.35349C3.60543 12.9523 2.99901 12.3459 2.99901 11.5979V6.47615H11.9536V5.33331H2.99901V5.24169C2.99901 3.75111 3.72703 2.43057 4.84688 1.61624L4.17795 0.592299C4.06359 0.417243 4.10994 0.180725 4.28148 0.0640204C4.45303 -0.0526836 4.6848 -0.00537999 4.79916 0.169676L5.4897 1.22669C6.08896 0.92926 6.76428 0.762041 7.47866 0.762041C8.19166 0.762041 8.86575 0.928617 9.46415 1.22498L10.1536 0.169676C10.2679 -0.00537999 10.4997 -0.0526836 10.6712 0.0640204ZM12.7042 5.69118C12.7042 5.07267 13.2056 4.57127 13.8241 4.57127C14.4426 4.57127 14.944 5.07267 14.944 5.69118V9.54651C14.944 10.165 14.4426 10.6664 13.8241 10.6664C13.2056 10.6664 12.7042 10.165 12.7042 9.54651V5.69118ZM1.13065 4.57127C0.512144 4.57127 0.0107422 5.07267 0.0107422 5.69118V9.54651C0.0107422 10.165 0.512144 10.6664 1.13065 10.6664C1.74916 10.6664 2.25057 10.165 2.25057 9.54651V5.69118C2.25057 5.07267 1.74916 4.57127 1.13065 4.57127ZM5.79561 4.19035C6.10486 4.19035 6.35557 3.93452 6.35557 3.61893C6.35557 3.30335 6.10486 3.04751 5.79561 3.04751C5.48635 3.04751 5.23565 3.30335 5.23565 3.61893C5.23565 3.93452 5.48635 4.19035 5.79561 4.19035ZM9.15633 4.1905C9.46558 4.1905 9.71628 3.93467 9.71628 3.61908C9.71628 3.30349 9.46558 3.04766 9.15633 3.04766C8.84707 3.04766 8.59637 3.30349 8.59637 3.61908C8.59637 3.93467 8.84707 4.1905 9.15633 4.1905Z"
      fill="currentColor"
    />
  </Glyph>
));

AndroidIcon.displayName = 'AndroidIcon';
