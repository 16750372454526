import clsx from 'clsx';

type Props = {
  className?: string;
} & ChildrenProp;

const MobileNavigationHeader: React.FC<Props> = ({ children, className, ...props }) => {
  const classes = clsx('text-neutralTextLoud text-xs font-semibold px-3.5 py-4', className);

  return (
    <h5 {...props} className={classes}>
      {children}
    </h5>
  );
};

export { MobileNavigationHeader };
