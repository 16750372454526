import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useNavigation } from './useNavigation';

/**
 * Closes visible dropdown if page is rerouted
 * @returns useEffect listens for path change
 */
export const useNavDropdown = () => {
  const router = useRouter();

  const {
    state: { dropDownVisible },
    handleMouseLeave
  } = useNavigation();

  return useEffect(() => {
    if (dropDownVisible) {
      handleMouseLeave();
    }
  }, [router.asPath]);
};
