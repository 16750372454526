import { HeadingText } from 'components/common/Typography';
import { useContentfulUiComponentFromQuery } from 'hooks/useContentful/useContentful';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { FooterBottomRow } from './FooterBottomRow';
import { FooterLinks } from './FooterLinks';

const useStyles = makeStyles({
  wrapper: 'bg-white border-t-2 border-dashed border-neutralBorder',
  hamburgerIcon: 'text-xs'
});

const PublicFooter = () => {
  const styles = useStyles();
  const footerContent = useContentfulUiComponentFromQuery('public-footer');

  if (!footerContent) {
    return <></>;
  }

  // if footer is not present on contentful query, ignore it
  // Example: RoutePlanerPage
  return (
    <footer className={styles.wrapper}>
      <HeadingText as="h2" size="MD" className="sr-only">
        {footerContent.name}
      </HeadingText>
      <FooterLinks />
      <FooterBottomRow />
    </footer>
  );
};

export { PublicFooter };
