import React from 'react';

import { NavLinkContent } from './NavLinkContent';
import { NavLinkInner } from './NavLinkInner';
import { NavLinkInnerProps } from './types';

const NavLinkComponent: React.ComponentType<NavLinkInnerProps> = React.memo((props) => {
  return (
    <NavLinkInner {...props}>
      <NavLinkContent {...props} />
    </NavLinkInner>
  );
});

NavLinkComponent.displayName = 'NavLinkComponent';

export { NavLinkComponent };
