import { ContentfulUiComponentItem } from '@truckmap/common';
import { NavLink } from 'components/common/NavLink';
import { useNavigation } from 'hooks/useNavigation/useNavigation';
import { makeStyles } from 'lib/makeStyles';

import { useCustomNextElement, useKeydownEscapeListener } from '../../hooks/useKeyboard';
import { Props as SubNavigationProps } from './SubNavigation';

type Props = {
  items?: ContentfulUiComponentItem[];
  index: number;
} & Pick<SubNavigationProps, 'currentRef'>;

const useStyles = makeStyles({
  containerInner: ['divide-y divide-neutralBorder'],
  link: [
    'px-6 py-4',
    'focus-default',
    'transition-colors',
    'hover:bg-neutralBackgroundHover focus:bg-secondaryBackground'
  ],
  root: ({ dropDownVisible }) => [
    '-full h-full invisible',
    {
      'animate-fade-in-300 animation-delay-150': dropDownVisible,
      'animate-fade-out-300': !dropDownVisible
    }
  ]
});

export const SubNavigationItems = ({ items, index, currentRef }: Props) => {
  const {
    state: { currentIndex, dropDownVisible },
    handleMouseLeave
  } = useNavigation();
  const isCurrent = index === currentIndex;

  const styles = useStyles({ dropDownVisible });
  const { handleKeydownTab } = useCustomNextElement({
    nextElement: currentRef,
    callback: handleMouseLeave
  });

  useKeydownEscapeListener({ enable: dropDownVisible, callback: handleMouseLeave });

  if (!items || items?.length === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      {items.map((entry, index) => (
        <div className={styles.containerInner} key={entry.sys.id}>
          <NavLink
            {...entry}
            handleCustomKeyDown={handleKeydownTab(items.length === index + 1)}
            className={styles.link}
            isFocusable={isCurrent && dropDownVisible}
          />
        </div>
      ))}
    </div>
  );
};
