import { InfoTooltip } from 'components/common/TooltipNotifications';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { InfoIcon } from 'icons/alert/InfoIcon';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

export const useStyles = makeStyles({
  infoIcon: 'text-neutralIcon shrink-0 cursor-pointer'
});

const AuthTooltip = React.memo(() => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <InfoTooltip isSquare message={t('AUTH_TOOLTIP')}>
      <InfoIcon className={styles.infoIcon} />
    </InfoTooltip>
  );
});

AuthTooltip.displayName = 'AuthTooltip';

export { AuthTooltip };
