import { BodyText } from 'components/common/Typography';
import {
  useContentfulUiComponentFromQuery,
  useContentfulUiComponentItems
} from 'hooks/useContentful/useContentful';
import { makeStyles } from 'lib/makeStyles';
import Link from 'next/link';
import React from 'react';

const useStyles = makeStyles({
  linksWrapper: 'max-w-5xl mx-auto py-10 px-4 sm:px-6 lg:(py-16 px-8)',
  linksInner: ['grid grid-cols-[2fr_1fr] md:px-6 md:flex md:justify-between md:flex-nowrap'],
  columnWrapper: 'md:w-auto mb-8 md:mb-0 pr-8 md:pr-0',
  columnHeading: 'text-secondaryText tracking-wider',
  columnLinks: 'mt-4 space-y-2',
  linkText: 'text-base text-secondaryText hover:text-primaryText'
});

export const FooterLinks = () => {
  const styles = useStyles();
  const footerContent = useContentfulUiComponentFromQuery('public-footer');
  const footerLinks = useContentfulUiComponentItems(footerContent, 'NavbarLink');

  return (
    <div className={styles.linksWrapper}>
      <div className={styles.linksInner}>
        {footerLinks.map((column) => {
          const { items } = column?.itemsCollection || {};

          return (
            items?.length && (
              <div key={column.sys.id} className={styles.columnWrapper}>
                <BodyText as="h3" bold className={styles.columnHeading}>
                  {column.primaryText}
                </BodyText>
                <ul className={styles.columnLinks}>
                  {items.map((item) => (
                    <li key={item.sys.id}>
                      <Link passHref href={item.primaryLink.url} legacyBehavior>
                        <a className={styles.linkText}>{item.primaryText}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

FooterLinks.displayName = 'FooterLinks';
