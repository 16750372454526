import { useRouter } from 'next/router';
import { useEffect } from 'react';

type ActionProps = {
  action: () => void;
  onComplete?: (args: unknown) => void;
};

const useOnRouteChange = ({ action, onComplete }: ActionProps) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', action);
    if (onComplete) {
      router.events.on('routeChangeComplete', onComplete);
    }
    return () => {
      router.events.off('routeChangeStart', action);
      if (onComplete) {
        router.events.on('routeChangeComplete', onComplete);
      }
    };
  }, []);
};

export { useOnRouteChange };
