import { ExternalLink } from 'components/ExternalLink';
import { memo } from 'react';

export const SocialLink: React.ComponentType<
  React.AnchorHTMLAttributes<HTMLAnchorElement> & { title: string }
> = memo(({ children, title, ...props }) => (
  <>
    <ExternalLink
      {...props}
      aria-label={title}
      className="text-xl text-primaryIcon hover:text-primaryIconHover md:text-2xl">
      {children}
    </ExternalLink>
  </>
));

SocialLink.displayName = 'SocialLink';
