import { HeroIcon } from 'components/common/HeroIcon';
import { Modal, ModalProps } from 'components/common/Modal';
import { ModalTitleWithIcon } from 'components/common/Modal/ModalContent';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { GeneralSettings, GeneralSettingsProps } from './GeneralSettings';

type SettingsModalProps = ParentClassNameProp &
  Pick<ModalProps, 'open' | 'onClose'> &
  Pick<GeneralSettingsProps, 'onSubmit'>;

const useStyles = makeStyles<SettingsModalProps>()({
  root: (props) => [props.className],
  icon: ['w-5 h-5', 'mr-2', 'shrink-0', 'bg-primaryText bg-no-repeat']
});

const SettingsModal = React.memo((props: SettingsModalProps) => {
  const styles = useStyles(props);
  const { t } = useTranslation();

  return (
    <Modal
      title={
        <ModalTitleWithIcon
          title={t('USER_SETTINGS_BUTTON')}
          icon={<HeroIcon className={styles.icon} type="solid-mini" name="user-circle" />}
        />
      }
      className={styles.root}
      open={props.open}
      onClose={props.onClose}>
      <PanelItem>
        <GeneralSettings showTitle={false} fullWidth onSubmit={props.onSubmit} />
      </PanelItem>
    </Modal>
  );
});

SettingsModal.displayName = 'SettingsModal';

export { SettingsModal };
