import { authFeatures } from 'components/Auth/AuthModal/FeatureBullets/FeatureBullets';
import { HeroIcon } from 'components/common/HeroIcon';
import { Translation } from 'components/common/Translation';
import { BodyText, HeadingText } from 'components/common/Typography';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { useStyles as useAuthStyles } from '../AuthForm/AuthPhoneStep';
import { FeatureBullets } from './FeatureBullets';

const useStyles = makeStyles({
  leftCol: ['flex flex-col relative h-full'],
  leftColInner: ['flex flex-col space-y-12 sm:ml-4 mt-4'],
  grid: 'relative sm:grid sm:grid-cols-2 w-full h-full sm:h-[490px]',
  mapFadeImage: 'absolute w-full hidden sm:block sm:bottom-0 sm:-right-60',
  mapFadeImageMobile: 'absolute w-full sm:hidden bottom-40 -right-40 left-0',
  componentImage: 'hidden sm:block absolute bottom-0 -right-10 w-[300px]',
  rightCol: 'hidden sm:block',
  authFormOuter: 'absolute sm:relative bottom-4 bg-white',
  authFormBackground: 'absolute bottom-0 -right-60 w-[500px] h-[500px] sm:hidden',
  label: 'w-full text-left',
  centerText: 'text-center',
  header: 'py-1.5 pt-6 sm:pt-1.5'
});

const AuthModalContentLG: React.FC<ChildrenProp> = React.memo(({ children }) => {
  const styles = useStyles();
  const authStyles = useAuthStyles();
  return (
    <>
      <img
        src="/images/auth/map-fade.png"
        alt=""
        aria-hidden={true}
        className={styles.mapFadeImage}
      />
      <img
        src="/images/auth/map-fade-circle.png"
        alt=""
        aria-hidden={true}
        className={styles.mapFadeImageMobile}
      />
      <img
        src="/images/auth/component.png"
        alt=""
        aria-hidden={true}
        className={styles.componentImage}
      />
      <PanelItem className={styles.header} column>
        <PanelItem>
          <HeroIcon className={authStyles.icon} type="outline" name="user-circle" />
          <HeadingText size="XS">
            <Translation textKey="AUTH_HEADING" />
          </HeadingText>
        </PanelItem>
        <BodyText className={styles.centerText} large>
          <Translation textKey="AUTH_SUBHEADING" />
        </BodyText>
      </PanelItem>
      <div className={styles.grid}>
        <div className={styles.leftCol}>
          <div className={styles.leftColInner}>
            <FeatureBullets features={authFeatures} />
            <div className={styles.authFormOuter}>{children}</div>
          </div>
        </div>
        <div className={styles.rightCol} />
      </div>
    </>
  );
});

AuthModalContentLG.displayName = 'AuthModalContentLG';

export { AuthModalContentLG };
