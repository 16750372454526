import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const DownArrowIcon = React.memo((props: GlyphProps) => (
  <Glyph width={8} height={5} {...props}>
    <path
      d="M1 1l3 3 3-3"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Glyph>
));

DownArrowIcon.displayName = 'DownArrowIcon';

export { DownArrowIcon };
