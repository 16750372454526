import { IAuthLoginPayload } from '@truckmap/common';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AuthModalContentLG } from '../AuthModal';
import { AuthModal, AuthModalProps } from '../AuthModal/AuthModal';
import { AuthConfirmCodeStep } from './AuthConfirmCodeStep';
import { AuthEmail } from './AuthEmail';
import { AuthFormContext, AuthFormSteps } from './AuthFormContext';
import { AuthPhoneStep, AuthPhoneStepProps } from './AuthPhoneStep';

type AuthPhoneFormProps = {
  hasPhoneModal?: boolean;
  showPhoneModal?: boolean;
  onClosePhoneModal?: GenericHandler;
} & Pick<AuthModalProps, 'size'>;

type AuthConfirmCodeFormProps = {
  hasConfirmCodeModal?: boolean;
  showBackCodeStep?: boolean;
  hidePhoneStepOnConfirmCode?: boolean;
};

type AuthFormProps = {
  showBackButton?: boolean;
  showClose?: boolean;
  showUsernameForm?: boolean;
} & AuthPhoneStepProps &
  AuthPhoneFormProps &
  AuthConfirmCodeFormProps;

export const AuthForm = React.memo(
  ({
    hasConfirmCodeModal = true,
    hasPhoneModal,
    size,
    showPhoneModal,
    showUsernameForm,
    showBackCodeStep = true,
    onClosePhoneModal,
    showBackButton = false,
    hidePhoneStepOnConfirmCode = true,
    showClose = true,
    ...props
  }: AuthFormProps) => {
    const userCache = useRef<IAuthLoginPayload>();
    const [formStep, setFormStep] = useState<AuthFormSteps>('phone');
    const [showConfirmCodeModal, setConfirmCodeModal] = useState(false);
    const onBackModal = useCallback(() => setFormStep('phone'), [formStep]);
    const onCloseModal = useCallback(() => {
      onClosePhoneModal && onClosePhoneModal();
      return setFormStep('phone');
    }, [formStep]);

    useEffect(() => {
      setConfirmCodeModal(formStep === 'code');
    }, [formStep, showConfirmCodeModal]);

    return (
      <AuthFormContext.Provider
        value={{
          setFormStep,
          userCache
        }}>
        <>
          {!hasPhoneModal &&
            (hidePhoneStepOnConfirmCode ? (
              formStep === 'phone' && <AuthPhoneStep {...props} />
            ) : (
              <AuthPhoneStep {...props} />
            ))}

          {!hasConfirmCodeModal && formStep === 'code' && <AuthConfirmCodeStep />}

          {hasPhoneModal && formStep === 'phone' && (
            <AuthModal
              size={size}
              open={showPhoneModal}
              showBack={showBackButton}
              showClose={showClose}
              onClose={onClosePhoneModal}
              hasMobileFullHeight={size === 'lg'}>
              {size === 'sm' ? (
                <AuthPhoneStep {...props} />
              ) : (
                <AuthModalContentLG>
                  <AuthPhoneStep {...props} />
                </AuthModalContentLG>
              )}
            </AuthModal>
          )}

          {hasConfirmCodeModal && (
            <AuthModal
              showBack={showBackCodeStep}
              onBack={onBackModal}
              open={showConfirmCodeModal}
              onClose={onCloseModal}
              hasMobileFullHeight={false}>
              <AuthConfirmCodeStep />
            </AuthModal>
          )}

          {showUsernameForm && (
            <AuthModal open showClose={false} hasMobileFullHeight={false}>
              <AuthEmail />
            </AuthModal>
          )}
        </>
      </AuthFormContext.Provider>
    );
  }
);

AuthForm.displayName = 'AuthForm';
