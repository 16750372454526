import { Button } from 'components/common/Button';
import { Translation } from 'components/common/Translation';
import { makeStyles } from 'lib/makeStyles';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { AuthGateProps } from '../Auth';
import { RuntimeFeatureEnabled } from '../FeatureEnabled';

type DashboardButtonProps = Pick<AuthGateProps, 'fullWidthButton'>;

const useStyles = makeStyles({
  button: 'mr-2'
});

const DashboardButton = React.memo(({ fullWidthButton }: DashboardButtonProps) => {
  const router = useRouter();
  const styles = useStyles();

  const dashboardOnClick = useCallback(() => {
    router.push('/dashboard');
  }, [router]);

  return (
    <RuntimeFeatureEnabled name="dashboard">
      <Button
        className={styles.button}
        isPrimary
        fullWidth={fullWidthButton}
        onClick={dashboardOnClick}>
        <Translation textKey="BUTTON_GO_TO_DASHBOARD" />
      </Button>
    </RuntimeFeatureEnabled>
  );
});

DashboardButton.displayName = 'DashboardButton';

export { DashboardButton };
