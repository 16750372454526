import { PanelBasic } from 'components/layout/Panel/PanelBasic';
import React, { memo } from 'react';

import { ListItem, ListItemProps } from './ListItem';

export type PopoverListOptionsProps = {
  options?: ListItemProps[];
  onClose?: GenericHandler;
} & ParentClassNameProp;

const PopoverListOptions = memo(({ options, className, onClose }: PopoverListOptionsProps) => {
  const formattedOptions = options?.map(({ onClick, ...props }, index) => {
    return (
      <ListItem
        key={`popover_list_item_${index}`}
        onClick={(e) => {
          onClick && onClick(e);
          onClose && onClose(e);
        }}
        {...props}
      />
    );
  });

  return (
    <PanelBasic defaultPadding={false} className={className}>
      {formattedOptions}
    </PanelBasic>
  );
});

PopoverListOptions.displayName = 'PopoverListOptions';

export { PopoverListOptions };
