import { Breakpoint } from 'components/common/Breakpoint';
import { Button } from 'components/common/Button';
import { HeroIcon } from 'components/common/HeroIcon';
import { Translation } from 'components/common/Translation';
import { MetaText } from 'components/common/Typography';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { LogoutLink } from 'components/LogoutLink';
import { NonPrefetchLink } from 'components/NonPrefetchLink';
import { UserMenuDesktopProps } from 'components/UserMenu/UserMenuDesktop';
import { produce } from 'immer';
import { makeStyles } from 'lib/makeStyles';
import { PublicURLModel } from 'models/PublicURLModel';
import React from 'react';
import { useRecoilCallback } from 'recoil';
import { truckOptionsModalAtom } from 'recoil/truckOptions/truckOptionsModalAtom';

import { AuthGateProps } from '../Auth';

type UserMenuMobileProps = ParentClassNameProp &
  Pick<AuthGateProps, 'fullWidthButton'> &
  UserMenuDesktopProps;

const useStyles = makeStyles<Omit<UserMenuMobileProps, 'fullWidthButton'>>()({
  root: (props) => [props.className],
  icon: ['w-5 h-5', 'shrink-0', 'bg-primary bg-no-repeat'],
  createRouteIcon: [
    'w-4 h-4',
    'shrink-0',
    'bg-white bg-no-repeat',
    'outline-none focus:outline-none'
  ],
  settingsIcon: [
    'w-4 h-4',
    'shrink-0',
    'bg-primaryBackgroundDark bg-no-repeat',
    'outline-none focus:outline-none'
  ],
  panelBasic: ['w-44', 'overflow-hidden'],
  listItemLink: ['block w-full'],
  logoutLinkWrapper: ['bg-neutralBackground', 'px-3', 'border-t border-gray-040', 'w-full'],
  dashboardButton: 'mr-4'
});

export const UserMenuMobile = React.memo((props: UserMenuMobileProps) => {
  const styles = useStyles(props);

  const showTruckSettingsMobile = useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          truckOptionsModalAtom,
          produce((draft) => {
            draft.modalOpen = true;

            // to show main menu on mobile
            draft.activeOption = null;
          })
        );
      },
    []
  );

  return (
    <Breakpoint down="MD">
      <PanelItem fullWidth spacing="XS" column>
        <NonPrefetchLink href={PublicURLModel.ROUTE} className={styles.listItemLink}>
          <Button fullWidth as="div" isPrimary>
            <PanelItem fullWidth justify="center">
              <HeroIcon className={styles.createRouteIcon} type="solid-mini" name="plus-circle" />
              <MetaText disableColorStyle>New Route</MetaText>
            </PanelItem>
          </Button>
        </NonPrefetchLink>
        <Button fullWidth as="div" onClick={props.showSettings}>
          <PanelItem fullWidth justify="center">
            <HeroIcon className={styles.settingsIcon} type="solid-mini" name="user-circle" />
            <MetaText bold>
              <Translation textKey="SETTINGS" />
            </MetaText>
          </PanelItem>
        </Button>
        <Button fullWidth as="div" onClick={showTruckSettingsMobile}>
          <PanelItem fullWidth justify="center">
            <HeroIcon className={styles.settingsIcon} type="solid" name="truck" />
            <MetaText bold>
              <Translation textKey="TRUCK_SETTINGS_BUTTON" />
            </MetaText>
          </PanelItem>
        </Button>
        <Button fullWidth as="div">
          <PanelItem fullWidth justify="center">
            <LogoutLink hasPaddingY={false} bold />
          </PanelItem>
        </Button>
      </PanelItem>
    </Breakpoint>
  );
});

UserMenuMobile.displayName = 'UserMenuMobile';
