import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { navigationAtom } from 'recoil/navigation';

const useNavigation = () => {
  const [state, setState] = useRecoilState(navigationAtom);

  const handleMouseEnter = useCallback(
    (i: number) => {
      const lastIndex = state.activeIndices[state.activeIndices.length - 1];

      if (i < 2) {
        setState({
          activeIndices: [lastIndex],
          isAnimating: false,
          dropDownVisible: false,
          currentIndex: lastIndex
        });
        return;
      }

      if (!state.dropDownVisible) {
        setState({
          activeIndices: [i],
          isAnimating: true,
          dropDownVisible: true,
          currentIndex: i
        });
        return;
      }

      if (lastIndex === i) return;

      setState({
        activeIndices: [lastIndex, i],
        isAnimating: false,
        dropDownVisible: true,
        currentIndex: i
      });
    },
    [state]
  );

  const handleMouseLeave = useCallback(() => {
    setState({
      ...state,
      isAnimating: false,
      dropDownVisible: false
    });
  }, [state]);

  return {
    handleMouseLeave,
    handleMouseEnter,
    state
  };
};

export { useNavigation };
