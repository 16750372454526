import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const FacebookIcon = React.memo((props: GlyphProps) => (
  <Glyph width={20} height={20} {...props}>
    <path
      d="M17.5 0H2.5C2.5 0 0 0 0 2.5V17.5C0 17.5 0 20 2.5 20H10.6817V12.255H8.07417V9.2375H10.6817V7.00833C10.6817 4.42583 12.26 3.02083 14.5642 3.02083C15.6683 3.02083 16.6175 3.10167 16.8942 3.13833V5.83833H15.2933C14.0433 5.83833 13.8 6.43917 13.8 7.31417V9.24H16.7867L16.3992 12.265H13.8V20H17.5C20 20 20 17.5 20 17.5V2.5C20 0 17.5 0 17.5 0Z"
      fill="currentColor"
    />
  </Glyph>
));

FacebookIcon.displayName = 'FacebookIcon';

export { FacebookIcon };
