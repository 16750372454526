import { PanelItem } from 'components/layout/Panel/PanelItem';
import { NonPrefetchLink, NonPrefetchLinkProps } from 'components/NonPrefetchLink';
import { useCustomKeyDown } from 'hooks/useKeyboard';
import { makeStyles } from 'lib/makeStyles';
import { memo, ReactNode } from 'react';

import { MetaText } from '../Typography';

export type ListOptionValue = {
  value?: string | number;
};

export type ListOptionClickHandler = {
  e?: GenericEvent;
} & ListOptionValue;

export type ListItemProps = {
  label?: ReactNode | string;
  onClick?: ({ e, value }: ListOptionClickHandler) => void;
} & ListOptionValue &
  ParentClassNameProp &
  Pick<Partial<NonPrefetchLinkProps>, 'href'>;

const useStyles = makeStyles<ParentClassNameProp>()({
  root: (props) => ['group rounded px-2', props.className]
});

export const ListItem = memo(({ className, onClick, href, label, value }: ListItemProps) => {
  const styles = useStyles({ className });
  const handleClick = (e) => {
    e?.preventDefault(e);
    onClick && onClick({ e, value });

    return;
  };

  const handleCustomKeyDown = useCustomKeyDown({
    onEnterOrSpace: handleClick
  });

  const labelOrAppend =
    label && typeof label !== 'string' ? (
      label
    ) : (
      <MetaText enableHover="group">{label ?? value}</MetaText>
    );

  return (
    <PanelItem padding={false} className={styles.root}>
      {href ? (
        <NonPrefetchLink href={href}>{labelOrAppend}</NonPrefetchLink>
      ) : (
        <div role="button" tabIndex={0} onClick={handleClick} onKeyDown={handleCustomKeyDown}>
          {labelOrAppend}
        </div>
      )}
    </PanelItem>
  );
});

ListItem.displayName = 'ListItem';
