import { makeStyles } from 'lib/makeStyles';
import Link from 'next/link';
import React from 'react';

import { NavLinkInnerProps } from './types';

const useStyles = makeStyles<ParentClassNameProp>()({
  linkClass: ({ className }) => ['flex items-center', className]
});

export const NavLinkInner: React.FC<NavLinkInnerProps & ChildrenProp> = React.memo(
  ({ children, handleCustomKeyDown, isFocusable, icon, className, isPrefetchLink, href }) => {
    const styles = useStyles({ className });

    // href is set on uplevel component
    return isPrefetchLink ? (
      <Link href={href} passHref legacyBehavior>
        <a
          role="link"
          className={styles.linkClass}
          onKeyDown={handleCustomKeyDown}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={isFocusable ? 0 : -1}
          style={{ width: icon ? 350 : 200 }}>
          {children}
        </a>
      </Link>
    ) : (
      <a
        href={href}
        className={styles.linkClass}
        onKeyDown={handleCustomKeyDown}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={isFocusable ? 0 : -1}
        style={{ width: 200 }}>
        {children}
      </a>
    );
  }
);

NavLinkInner.displayName = 'NavLinkInner';
