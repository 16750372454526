import { useEffect, useRef } from 'react';
import { truckMapConfig } from 'truckMapConfig';

export const useStickyVariant = () => {
  if (!truckMapConfig.isClientSideRender) {
    return;
  }
  const ref = useRef();

  useEffect(() => {
    const page = document.querySelector('#__next');

    if (page) {
      const observer = new IntersectionObserver(([entry]) => {
        page.classList.toggle('tmsticky', !entry.isIntersecting);
      });
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return ref;
};
