import { ContentfulLink } from '@truckmap/common';
import { PlaceSearchNavbarItem } from 'components/Place/PlaceSearch/PlaceSearchNavbarItem/PlaceSearchNavbarItem';
import { useNavbarItem, useNavDropdown, useNavigation } from 'hooks/useNavigation';
import { DownArrowIcon } from 'icons/monochrome/DownArrowIcon';
import { makeStyles } from 'lib/makeStyles';
import Link from 'next/link';
import React from 'react';

export type Props = {
  onMouseEnter?: (index: number) => void;
  onKeyEscape?: () => void;
  index: number;
  link?: ContentfulLink;
} & Required<ChildrenProp>;

export const useStyles = makeStyles({
  wrapper: 'relative focus-default',
  link: [
    'inline-flex items-center py-6 relative transition-colors',
    'whitespace-nowrap',
    'text-primaryText dark:text-neutralTextDark lg:tmsticky:text-primaryText text-sm font-medium hover:text-secondaryText'
  ],
  icon: 'ml-2'
});

const NavbarItemComponent: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { children, index, onMouseEnter, onKeyEscape, link },
  ref
) => {
  const styles = useStyles();
  const url = link?.url || '#';
  const WrapperElement = index > 1 ? 'div' : 'a';
  const {
    state: { currentIndex, dropDownVisible }
  } = useNavigation();
  const { onMouseEnterProxy, handleCustomKeyDown } = useNavbarItem({
    onMouseEnter,
    onKeyEscape,
    index
  });

  useNavDropdown();

  const ChildElement = (
    <WrapperElement className={styles.link}>
      {children}
      {index > 1 && <DownArrowIcon className={styles.icon} style={{ fontSize: '0.50rem' }} />}
    </WrapperElement>
  );
  const isPlacesMiddleware = url === '/places';

  if (index > 1) {
    /*
      We remove last item from the tabbing order when previous item's dropdown is open.
      This makes dropdown items next in the tabbing order.
    */
    return (
      <div
        role="button"
        tabIndex={index === 3 && currentIndex === 2 && dropDownVisible ? -1 : 0}
        className={styles.wrapper}
        onMouseEnter={onMouseEnterProxy}
        onKeyDown={handleCustomKeyDown}
        ref={ref}>
        {ChildElement}
      </div>
    );
  }

  return (
    <div className={styles.wrapper} onMouseEnter={onMouseEnterProxy} ref={ref}>
      {!isPlacesMiddleware ? (
        <Link passHref href={url} className={styles.link}>
          <>
            {children}
            {index > 1 && <DownArrowIcon className={styles.icon} style={{ fontSize: '0.50rem' }} />}
          </>
        </Link>
      ) : (
        <PlaceSearchNavbarItem className={styles.link} href={url}>
          {children}
        </PlaceSearchNavbarItem>
      )}
    </div>
  );
};

export const NavbarItem = React.forwardRef(NavbarItemComponent);

NavbarItem.displayName = 'NavbarItem';
