import { useSEOLocaleRoutes } from 'hooks/useSEOLocaleRoutes';
import { NextSeo } from 'next-seo';

import { usePageSerializedContext } from './PageContext';

const PageSeo: React.ComponentType = () => {
  const serializedPage = usePageSerializedContext();
  const { languageAlternates, localeRoute, baseUrl } = useSEOLocaleRoutes(
    serializedPage.relativePath
  );

  return (
    <NextSeo
      title={serializedPage.seoTitle}
      description={serializedPage.seoDescription}
      languageAlternates={languageAlternates}
      canonical={
        localeRoute
          ? `${baseUrl}${localeRoute}${serializedPage.relativePath}`
          : `${baseUrl}${serializedPage.relativePath}`
      }
      openGraph={
        serializedPage && {
          type: 'website',
          url: baseUrl + serializedPage.relativePath,
          images: serializedPage.previewImage && [
            {
              url: serializedPage.previewImage.url,
              width: serializedPage.previewImage?.details?.image.width,
              height: serializedPage.previewImage?.details?.image.width,
              alt: serializedPage.previewImage.title
            }
          ]
        }
      }
    />
  );
};

export { PageSeo };
