import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const HamburgerIcon = React.memo((props: GlyphProps) => (
  <Glyph width={12} height={10} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1a1 1 0 01-1 1H1a1 1 0 010-2h10a1 1 0 011 1zm0 4a1 1 0 01-1 1H1a1 1 0 010-2h10a1 1 0 011 1zm-1 5a1 1 0 100-2H1a1 1 0 100 2h10z"
      fill="currentColor"
    />
  </Glyph>
));

HamburgerIcon.displayName = 'HamburgerIcon';

export { HamburgerIcon };
