import { Modal } from 'components/common/Modal';
import { useRecoilValue } from 'recoil';
import { mobileMenuAtom, useToggleMobileMenu } from 'recoil/mobileMenu';

import { MobileNavigationContent } from './MobileNavigationContent';

const MobileNavigationModal = () => {
  const { open: mobileOpen } = useRecoilValue(mobileMenuAtom);

  const handleClose = useToggleMobileMenu();

  if (!mobileOpen) {
    return null;
  }

  return (
    <Modal open={mobileOpen} onClose={handleClose} size="full">
      <MobileNavigationContent />
    </Modal>
  );
};

MobileNavigationModal.displayName = 'MobileNavigationModal';

export { MobileNavigationModal };
