import { PanelItemProps } from 'components/layout/Panel/PanelItem';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

type ContainerProps = {
  hasDefaultPadding?: boolean;
  hasBackgroundColor?: boolean;
  size?: 'LG' | 'MD';
} & JSX.IntrinsicElements['div'] &
  ParentClassNameProp &
  Pick<PanelItemProps, 'paddingX' | 'paddingY'>;

const useStyles = makeStyles<ContainerProps>()({
  container: (props) => [
    'w-full',
    'mx-auto',
    {
      'max-w-7xl': props?.size === 'MD',
      'px-6': props?.paddingX === 'MD' && props?.hasDefaultPadding,
      'px-8': props?.paddingX === 'LG' && props?.hasDefaultPadding,
      'max-w-screen-2xl': props?.size === 'LG',
      'bg-primaryBackground': props?.hasBackgroundColor
    },
    props?.className
  ]
});

const Container: React.FC<ContainerProps> = React.memo(
  ({ children, paddingX = false, paddingY = false, ...props }) => {
    const styles = useStyles({ paddingX, paddingY, ...props });

    return <div className={styles.container}>{children}</div>;
  }
);

Container.defaultProps = {
  hasDefaultPadding: true,
  hasBackgroundColor: false,
  size: 'MD'
};

Container.displayName = 'Container';

export { Container };
