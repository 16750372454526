import { registerDevice } from '@truckmap/common';
import os from 'os';
import { v4 as uuidv4 } from 'uuid';

export async function getDeviceSecret() {
  try {
    const uuid = uuidv4().toString().toLowerCase();
    const platform = os.platform();
    const model = 'vercel';

    const device = await registerDevice({ uuid, platform, model });

    return device;
  } catch (err) {
    console.error(err);
    return err;
  }
}
