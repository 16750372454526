import { Logo } from 'components/common/Logo';
import { FacebookIcon } from 'icons/social/FacebookIcon';
import { LinkedinIcon } from 'icons/social/LinkedinIcon';
import { TwitterIcon } from 'icons/social/TwitterIcon';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { truckMapConfig } from 'truckMapConfig';

import { SocialLink } from './SocialLink';

const useStyles = makeStyles({
  bottomRowOuter: 'max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:py-6 lg:px-8',
  bottomRowInner: 'md:mt-4 grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-8 items-center',
  logoMobile: 'justify-center md:order-2 flex md:hidden',
  logoDesktop: 'justify-center md:order-2 hidden md:flex',
  copyrightWrapper: 'flex text-base text-secondaryText md:mt-0 md:justify-start order-2 md:order-1',
  socialLinksWrapper: 'flex space-x-4 md:space-x-6 justify-end order-3'
});

const FooterBottomRow = () => {
  const styles = useStyles();

  return (
    <div className={styles.bottomRowOuter}>
      <div className={styles.logoMobile}>
        <Logo />
      </div>
      <div className={styles.bottomRowInner}>
        <p className={styles.copyrightWrapper}>&copy; {new Date().getFullYear()} TruckMap Inc</p>
        <div className={styles.logoDesktop}>
          <Logo />
        </div>
        <div className={styles.socialLinksWrapper}>
          <SocialLink href={truckMapConfig.social.linkedin} title="Linkedin">
            <LinkedinIcon />
          </SocialLink>
          <SocialLink href={truckMapConfig.social.twitter} title="Twitter">
            <TwitterIcon />
          </SocialLink>
          <SocialLink href={truckMapConfig.social.facebook} title="Facebook">
            <FacebookIcon />
          </SocialLink>
        </div>
      </div>
    </div>
  );
};

FooterBottomRow.displayName = 'FooterBottomRow';

export { FooterBottomRow };
