import { atom } from 'recoil';

type NavigationAtom = {
  activeIndices: number[];
  isAnimating: boolean;
  dropDownVisible: boolean;
  currentIndex: number;
};

export const navigationAtom = atom<NavigationAtom>({
  key: 'navigationAtom',
  default: {
    activeIndices: [],
    isAnimating: false,
    dropDownVisible: false,
    currentIndex: 0
  }
});
