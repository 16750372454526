import { OpacityTransition } from 'components/common/Transitions/OpacityTransition';
import { useRecoilValue } from 'recoil';
import { autoCompleteAtom } from 'recoil/autoComplete';
import { truckMapConfig } from 'truckMapConfig';

const HamburgerMotion: React.FC<ChildrenProp> = ({ children }) => {
  const { visible: searchVisible } = useRecoilValue(autoCompleteAtom('publicSearch'));

  return (
    <OpacityTransition show={!(truckMapConfig.isClientSideRender && searchVisible)}>
      {children}
    </OpacityTransition>
  );
};

export { HamburgerMotion };
