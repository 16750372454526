import { produce } from 'immer';
import { useRecoilCallback } from 'recoil';

import { mobileMenuAtom } from './mobileMenuAtom';

export const useToggleMobileMenu = (deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          mobileMenuAtom,
          produce((draft) => {
            draft.open = !draft.open;
          })
        );
      },
    deps
  );

export const useHideMobileMenu = (deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          mobileMenuAtom,
          produce((draft) => {
            draft.open = false;
          })
        );
      },
    deps
  );
