import { MetaText } from 'components/common/Typography';
import { ExternalLink } from 'components/ExternalLink';
import useUserAgent from 'hooks/useAuth/useUserAgent';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { AndroidIcon } from 'icons/monochrome/AndroidIcon';
import { AppleIcon } from 'icons/monochrome/AppleIcon';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { pingEvent } from '../../../lib/analytics/event';

const useStyles = makeStyles({
  root: ['bg-primaryBackgroundDark items-center flex p-2 px-4 rounded-full'],
  iconContainer: ['w-auto h-full py-1', 'flex self-center', 'ml-2'],
  icon: ['h-full', 'box-border', 'text-white'],
  content: ['h-full', 'whitespace-nowrap']
});

const definedContent = {
  Android: {
    Icon: AndroidIcon,
    url: '/get/android'
  },
  iOS: {
    Icon: AppleIcon,
    url: '/get/ios'
  }
};

const DownloadBanner = React.memo(() => {
  const styles = useStyles();
  const name = useUserAgent();
  const { t } = useTranslation();

  const { Icon, url } = definedContent[name] || definedContent['iOS'];

  return (
    <ExternalLink
      href={url}
      aria-label={t('GET_THE_APP')}
      onClickCapture={() => pingEvent(`CLICK_DOWNLOAD_APP_BANNER_${name}`)}>
      <div className={styles.root}>
        <MetaText dark className={styles.content} whitespace="nowrap">
          {t('GET_THE_APP')}
        </MetaText>
        <div className={styles.iconContainer}>
          <Icon className={styles.icon} />
        </div>
      </div>
    </ExternalLink>
  );
});

DownloadBanner.displayName = 'DownloadBanner';

export { DownloadBanner };
