import { AppHead } from 'components/AppHead';
import { ColorVariantWrapper } from 'components/common/ColorVariantWrapper';
import { PageUIComponents } from 'components/contentful/PageUIComponents';
import { PublicFooter } from 'components/PublicFooter';
import { PublicNavigation } from 'components/PublicNavigation';
import { ContentfulContext } from 'hooks/useContentful/useContentful';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { ContentfulQueryProps } from 'types/common';

import { ClientSideDeviceSecret } from 'components/DeviceSecret/ClientSideDeviceSecret';
import { useDriftWidgetScript } from 'hooks/useDriftWidget';
import { PageContext, PageContextTypes } from './PageContext';
import { PageSeo } from './PageSeo';

export const usePageStyles = makeStyles<Pick<PageProps, 'disableScroll'>>()({
  root: (props) => [
    'flex flex-col',
    'min-h-screen',
    'bg-neutralBackgroundSoft dark:bg-primaryBackgroundDark',
    {
      'overflow-hidden': props?.disableScroll
    }
  ]
});

export type PageProps = PageContextTypes &
  ContentfulQueryProps & {
    disableScroll?: boolean;
  } & ChildrenProp;

export const Page: React.ComponentType<PageProps> = ({
  contentfulQuery,
  children,
  disableScroll = false,
  ...pageContextValues
}) => {
  const styles = usePageStyles({ disableScroll });
  const { variant } = pageContextValues?.page?.fields || {
    uiComponents: [],
    variant: 'DEFAULT'
  };

  useDriftWidgetScript();

  return (
    <ColorVariantWrapper variant={variant}>
      <div className={styles.root}>
        <AppHead />
        <ContentfulContext.Provider value={{ contentfulQuery }}>
          <PageContext.Provider value={pageContextValues}>
            {pageContextValues.page?.fields?.seoTitle && <PageSeo />}
            <PublicNavigation />
            {children ?? (
              <PageUIComponents uiComponents={pageContextValues.page?.fields?.uiComponents} />
            )}
            <PublicFooter />
          </PageContext.Provider>
        </ContentfulContext.Provider>
      </div>
      <ClientSideDeviceSecret />
    </ColorVariantWrapper>
  );
};
