import { GlyphProps } from 'components/common/Glyph';
import dynamic from 'next/dynamic';

export const getExportedModule = (path: string): string => {
  const tracedPath = path?.split && path.split('/');

  return (tracedPath && tracedPath[tracedPath.length - 1]) ?? null;
};

export const iconComponentResolver = (iconName: string) =>
  dynamic<GlyphProps>(() =>
    import('icons/' + iconName)
      .then((mod) => mod[getExportedModule(iconName)])
      .catch(() => {
        console.error(`Unable to find icon: ${iconName}`);
      })
  );
