import { setClientDeviceSecret } from '@truckmap/common';
import { getDeviceSecret } from 'lib/getDeviceSecret';
import useSWR from 'swr';
import { truckMapConfig } from 'truckMapConfig';

import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useAuthUser } from './useAuthUser';

export function useDeviceSecret() {
  const user = useAuthUser();
  const { id: userId = 'guest' } = user || {};

  const {
    data: deviceSecretState,
    isLoading,
    error
  } = useSWR(
    `${truckMapConfig.cookies.deviceSecret}_${userId}`,
    async () => {
      if (
        !truckMapConfig.isClientSideRender ||
        truckMapConfig.isStaging ||
        truckMapConfig.isSearchBot
      ) {
        return truckMapConfig.guestDeviceSecret;
      }

      const deviceSecret = Cookies.get(truckMapConfig.cookies.deviceSecret);

      if (deviceSecret?.length && deviceSecret !== truckMapConfig.guestDeviceSecret) {
        return deviceSecret;
      }

      const device = await getDeviceSecret();

      if (device.error) {
        throw device.error.message;
      }

      setClientDeviceSecret(device.secret);

      return device.secret;
    },
    {
      revalidateOnFocus: false
    }
  );

  useEffect(() => {
    if (deviceSecretState?.length) {
      setClientDeviceSecret(deviceSecretState);
      Cookies.set(truckMapConfig.cookies.deviceSecret, deviceSecretState);
    }
  }, [deviceSecretState, userId]);

  return {
    loading: isLoading,
    ready: !isLoading && deviceSecretState?.length,
    error,
    deviceSecret: deviceSecretState
  };
}
