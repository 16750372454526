import { PopoverStyleProps } from 'components/common/Popover';
import { useMutationObserver } from 'hooks/useUI/useMutationObserver';
import { useCallback, useMemo, useState } from 'react';

type PopoverPositionProps = {
  buttonRef: React.MutableRefObject<HTMLButtonElement>;
  drawerRef: React.MutableRefObject<HTMLDivElement>;
} & Pick<PopoverStyleProps, 'position'>;

/**
 * Returns x and y values for translating popover panel
 * @param buttonRef HTMLButtonElement | null
 * @param drawerRef HTMLDivElement | null
 * @param position  'topLeft' | 'topRight' | 'right' | 'left' | 'bottomLeft' | 'bottomRight'
 * @returns { translateX: number, translateY: number }
 */

export const usePopoverPosition = (props: PopoverPositionProps) => {
  const { position, buttonRef, drawerRef } = props;
  const [buttonRect, setButtonRect] = useState(null);
  const [drawerRect, setDrawerRect] = useState(null);
  const [translateX, setTranslateX] = useState<number>(0);
  const [translateY, setTranslateY] = useState<number>(0);

  const calculateTranslateValues = useCallback(() => {
    getRectangles();
    const buttonHeight: number = buttonRect?.height ?? 0;
    const drawerWidth: number = drawerRect?.width ?? 0;
    const drawerHeight: number = drawerRect?.height ?? 0;

    switch (position) {
      case 'topRight':
        setTranslateY(-(buttonHeight + drawerHeight));
        return;
      case 'topLeft':
        setTranslateY(-(buttonHeight + drawerHeight));
        return;
      case 'left':
        setTranslateX(-drawerWidth);
        setTranslateY(-buttonHeight);
        return;
      case 'right':
        setTranslateX(drawerWidth);
        setTranslateY(-buttonHeight);
        return;
      default:
        setTranslateY(0);
        setTranslateX(0);
    }
  }, [buttonRect, drawerRect, setTranslateY, setTranslateX]);

  const getRectangles = useCallback(() => {
    if (buttonRef?.current) {
      setButtonRect(buttonRef?.current?.getBoundingClientRect());
    }

    if (drawerRef?.current) {
      setDrawerRect(drawerRef?.current?.getBoundingClientRect());
    }
  }, [buttonRef, drawerRef, setButtonRect, setDrawerRect]);

  useMutationObserver(buttonRef, calculateTranslateValues);

  return useMemo(
    () => ({
      translateX,
      translateY
    }),
    [translateX, translateY]
  );
};
