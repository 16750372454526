import { Breakpoint } from 'components/common/Breakpoint';
import { RoundButton } from 'components/common/Button';
import { Divider } from 'components/common/Divider';
import { HeroIcon } from 'components/common/HeroIcon';
import { Popover } from 'components/common/Popover';
import { Translation } from 'components/common/Translation';
import { SmallText } from 'components/common/Typography';
import { PanelBasic } from 'components/layout/Panel/PanelBasic';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { LogoutLink } from 'components/LogoutLink';
import { NonPrefetchLink } from 'components/NonPrefetchLink';
import { truckOptionsList } from 'components/TruckOptions/TruckOptionsContent';
import { produce } from 'immer';
import { makeStyles } from 'lib/makeStyles';
import { PublicURLModel } from 'models/PublicURLModel';
import React from 'react';
import { useRecoilCallback } from 'recoil';
import { truckOptionsModalAtom } from 'recoil/truckOptions/truckOptionsModalAtom';

export type UserMenuDesktopProps = ParentClassNameProp & {
  showSettings: GenericHandler;
};

const useStyles = makeStyles<UserMenuDesktopProps>()({
  root: (props) => [props.className],
  icon: ['w-5 h-5', 'shrink-0', 'bg-tmBlue bg-no-repeat'],
  createRoute: [
    'w-4 h-4',
    'shrink-0',
    'bg-gray-600 bg-no-repeat',
    'outline-none focus:outline-none'
  ],
  panelBasic: ['w-44', 'overflow-hidden'],
  listItem: ['px-3 py-2', 'hover:bg-primaryBackgroundHover'],
  listItemLink: ['block w-full'],
  logoutLinkWrapper: [
    'bg-neutralBackground hover:bg-neutralBackground/70',
    'px-3',
    'border-t border-gray-040',
    'w-full'
  ],
  popoverButton: 'rounded-full p-0.5'
});

export const UserMenuDesktop = React.memo((props: UserMenuDesktopProps) => {
  const styles = useStyles(props);

  const showTruckSettings = useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          truckOptionsModalAtom,
          produce((draft) => {
            draft.modalOpen = true;
            draft.activeOption = truckOptionsList.equipmentTypes;
          })
        );
      },
    []
  );

  return (
    <Breakpoint up="LG" className={styles.root}>
      <Popover
        position="bottomRight"
        closeOnMouseLeave
        buttonClassName={styles.popoverButton}
        renderButton={() => (
          <RoundButton as="div" circle="SM">
            <HeroIcon className={styles.icon} type="solid" name="user" />
          </RoundButton>
        )}>
        <PanelBasic className={styles.panelBasic} type="popover" defaultPadding={false}>
          <ul>
            <li className={styles.listItem}>
              <NonPrefetchLink href={PublicURLModel.ROUTE} className={styles.listItemLink}>
                <PanelFlex fullWidth>
                  <HeroIcon className={styles.createRoute} type="solid-mini" name="plus-circle" />
                  <SmallText as="span">
                    <Translation textKey="BUTTON_CREATE_ROUTE" />
                  </SmallText>
                </PanelFlex>
              </NonPrefetchLink>
            </li>
            <Divider as="li" spacing={false} />
            <li className={styles.listItem}>
              <button className={styles.listItemLink} onClick={props.showSettings}>
                <PanelFlex fullWidth>
                  <HeroIcon className={styles.createRoute} type="solid-mini" name="user-circle" />
                  <SmallText as="span">
                    <Translation textKey="USER_SETTINGS_BUTTON" />
                  </SmallText>
                </PanelFlex>
              </button>
            </li>
            <Divider as="li" spacing={false} />
            <li className={styles.listItem}>
              <button className={styles.listItemLink} onClick={showTruckSettings}>
                <PanelFlex fullWidth>
                  <HeroIcon className={styles.createRoute} type="solid-mini" name="truck" />
                  <SmallText as="span">
                    <Translation textKey="TRUCK_SETTINGS_BUTTON" />
                  </SmallText>
                </PanelFlex>
              </button>
            </li>
            <li className={styles.logoutLinkWrapper}>
              <LogoutLink fullWidth hasPrimaryText />
            </li>
          </ul>
        </PanelBasic>
      </Popover>
    </Breakpoint>
  );
});

UserMenuDesktop.displayName = 'UserMenuDesktop';
