import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const TwitterIcon = React.memo((props: GlyphProps) => (
  <Glyph width={22} height={18} {...props}>
    <path
      d="M21.9569 2.12933C21.1457 2.4859 20.2795 2.7288 19.3674 2.83972C20.2969 2.27965 21.0119 1.39694 21.3501 0.343736C20.4784 0.852465 19.5123 1.22278 18.4838 1.42902C17.6625 0.549977 16.492 0 15.1922 0C12.7017 0 10.6824 2.01933 10.6824 4.50706C10.6824 4.86455 10.7236 5.20828 10.7988 5.53735C7.04979 5.36044 3.72701 3.56019 1.50327 0.838715C1.11187 1.50052 0.892796 2.26957 0.892796 3.10737C0.892796 4.67481 1.69026 6.0525 2.89838 6.86188C2.15866 6.83805 1.46294 6.63456 0.856131 6.29724V6.35315C0.856131 8.53931 2.40798 10.3625 4.47315 10.7777C4.09458 10.8795 3.69493 10.9345 3.2852 10.9345C2.99738 10.9345 2.72147 10.907 2.44556 10.8556C3.02396 12.6458 4.68672 13.9511 6.66572 13.9878C5.12579 15.1968 3.17428 15.9173 1.07246 15.9173C0.71497 15.9173 0.358402 15.8962 0 15.8558C2.0065 17.1336 4.37048 17.8807 6.92696 17.8807C15.2261 17.8807 19.7588 11.0096 19.7588 5.06071C19.7588 4.86913 19.7588 4.67572 19.7451 4.48323C20.626 3.85167 21.395 3.05329 22 2.14766L21.9569 2.12933Z"
      fill="currentColor"
    />
  </Glyph>
));

TwitterIcon.displayName = 'TwitterIcon';

export { TwitterIcon };
