import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

export const AppleIcon = React.memo((props: GlyphProps) => (
  <Glyph width={14} height={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.04427 4.61093C7.71368 4.61093 8.98837 3.74293 10.3025 3.87275C10.8523 3.88781 12.4169 4.07658 13.4264 5.45823C13.3449 5.50401 11.5625 6.48741 11.5818 8.50877C11.6039 10.9315 13.8393 11.7324 13.8667 11.7412C13.8533 11.7976 13.5166 12.8971 12.6782 14.0134C11.978 14.9962 11.2445 15.957 10.0793 15.9746C8.94762 15.999 8.56681 15.3493 7.26874 15.3493C5.95864 15.3493 5.55312 15.957 4.46749 15.999C3.35782 16.0373 2.51003 14.9498 1.7845 13.9758C0.334102 11.987 -0.794948 8.37142 0.718915 5.91104C1.45246 4.70438 2.78995 3.92732 4.21964 3.90662C5.33065 3.8853 6.36149 4.61093 7.04427 4.61093ZM10.3165 0C10.4274 0.946394 10.0279 1.87899 9.444 2.56448C8.83471 3.23932 7.85999 3.75547 6.91065 3.68899C6.78639 2.77835 7.2674 1.81 7.80587 1.21921C8.41449 0.548771 9.47139 0.0351213 10.3165 0Z"
      fill="currentColor"
    />
  </Glyph>
));

AppleIcon.displayName = 'AppleIcon';
