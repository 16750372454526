import { BreakpointProp, useBreakpoints } from 'hooks/useUI/useBreakpoints';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { ClientSideOnly } from '../ClientSideOnly';

export type BreakpointProps = {
  clientSide?: boolean;
  up?: BreakpointProp;
  down?: BreakpointProp;
} & ChildrenProp &
  ParentClassNameProp;

const useStyles = makeStyles<ParentClassNameProp>()({
  root: ({ className }) => [className]
});

export const Breakpoint: React.FC<BreakpointProps> = React.memo(
  ({ children, className, clientSide = false, up, down }: BreakpointProps) => {
    const { show } = useBreakpoints(up, down);
    const styles = useStyles({ className });

    const content = className ? <div className={styles.root}>{children}</div> : <>{children}</>;

    return !show ? <></> : clientSide ? <ClientSideOnly>{content}</ClientSideOnly> : content;
  }
);

Breakpoint.displayName = 'Breakpoint';
