import { ContentfulUiComponentItem } from '@truckmap/common';

import { SubNavigationItems } from './SubNavigationItems';

export type Props = {
  item: ContentfulUiComponentItem;
  index: number;
  currentRef: HTMLDivElement;
};

const SubNavigation = ({ currentRef, item, index }: Props) => {
  const currentItems = item?.itemsCollection?.items;

  if (!currentItems || !currentRef) {
    return null;
  }

  return (
    <SubNavigationItems
      currentRef={currentRef}
      items={item.itemsCollection.items}
      index={index}
      key={item.sys.id}
    />
  );
};

export { SubNavigation };
