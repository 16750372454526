import { useStyles as useInputStyles } from 'components/common/Input/Input';
import { makeStyles } from 'lib/makeStyles';
import React, { ReactNode } from 'react';
import { CommonIcon } from 'types/common';

import { DropdownIconButton } from '../DropdownList';
import { ListItemProps as ListItemProps } from './ListItem';
import { Popover, PopoverProps } from './Popover';

const useStyles = makeStyles({
  // TODO: add focus styles
  root: ['relative', 'flex justify-between', 'h-full px-2', 'rounded-lg'],
  button: 'min-w-[50px]',
  input: [
    'rounded-lg self-center mr-[90px] px-1 focus:outline-none',
    'placeholder:paragraph-600 placeholder:text-sm'
  ],
  popover: ['absolute right-2 top-[3px]', 'border-l border-secondaryBorder'],
  popoverButton: 'ml-1',
  popoverPanel: 'mt-2.5 -mr-2',
  icon: 'text-2xl mt-[7px] text-secondaryIcon'
});

export type SearchPopoverProps = {
  options: ListItemProps[];
  buttonLabel: string | ReactNode;
} & Pick<PopoverProps, 'position'> &
  CommonIcon;

const SearchPopover = ({ options, buttonLabel, position, icon }: SearchPopoverProps) => {
  const styles = useStyles();
  const inputStyles = useInputStyles({ hasBorder: true });
  const Icon = icon;

  return (
    <div className={inputStyles.inputWrapper}>
      <div className={styles.root}>
        {icon && <Icon className={styles.icon} />}
        <input className={styles.input} placeholder="Type a city" autoComplete="off" />
        <div className={styles.popover}>
          <Popover
            position={position}
            options={options}
            drawerClassName={styles.popoverPanel}
            renderButton={(open) => (
              <div className={styles.popoverButton}>
                <DropdownIconButton open={open}>{buttonLabel}</DropdownIconButton>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

SearchPopover.displayName = 'SearchPopover';

export { SearchPopover };
